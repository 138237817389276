import Vue from "vue";
import { debounce } from "@/utils";

/**
 * Mixin designed in accordance to the FilterTable component
 * Only need to override the default filter to make it work
 *
 */
const filterTableMixin = <F>(
  defaultFilter: F,
  unbouncedFields?: string[],
  fetchFirst = true
) =>
  Vue.extend({
    data() {
      return {
        defaultFilter: defaultFilter as F,
        filter: defaultFilter as F,
        unbouncedFields: unbouncedFields || [], // Array of filter filed names which should not be debounced
        debouncedFilter: null as ((...args: unknown[]) => void) | null
      };
    },
    computed: {
      isDefaultFilter(): boolean {
        return Object.entries(this.defaultFilter).every(
          ([k, v]) => this.filter[k as keyof F] === v
        );
      },
      computedFilter(): F {
        return Object.assign({}, this.filter);
      }
    },
    methods: {
      debounce,
      resetFilter(): void {
        this.filter = { ...this.defaultFilter };
      },
      // Override this hook to execute functions on filter change
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      onFilterChange(...args: unknown[]) {
        return null;
      }
    },
    created() {
      this.resetFilter();
      this.debouncedFilter = this.debounce(this.onFilterChange, 400);
      if (fetchFirst) {
        this.onFilterChange("");
      }
    },
    watch: {
      computedFilter: {
        handler(current: F, previous: F) {
          if (JSON.stringify(current) !== JSON.stringify(previous)) {
            const isDebouncable =
              !!previous &&
              Object.entries(current)
                .filter(([f]) => this.unbouncedFields.includes(f))
                .every(([f, v]) => previous[f as keyof F] === v);
            if (this.debouncedFilter && isDebouncable) {
              this.debouncedFilter(current);
            } else {
              this.onFilterChange(current);
            }
          }
        },
        deep: true,
        immediate: false
      }
    }
  });

export default filterTableMixin;
