






























































































import Vue, { PropType } from "vue";
import Loader from "@/components/layout/Loader.vue";
import { BvTableFieldArray, BIconTrash } from "bootstrap-vue";

export default Vue.extend({
  components: { Loader, BIconTrash },
  computed: {
    f(): BvTableFieldArray {
      return [
        ...this.fields,
        {
          key: "actions",
          label: "Actions",
          thClass: "actions-column",
          tdClass: "actions-column"
        }
      ];
    }
  },
  props: {
    fields: {
      type: Array as PropType<BvTableFieldArray>,
      required: true
    },
    items: {
      type: Array
    },
    id: {
      type: String
    },
    hasAction: {
      type: Boolean,
      default: true
    },
    canArchive: {
      type: Boolean,
      default: false
    },
    canDelete: {
      type: Boolean,
      default: false
    },
    isBusy: {
      type: Boolean,
      default: false
    },
    showReset: {
      type: Boolean,
      default: false
    },
    emptyText: {
      type: String,
      default: "Nothing found. Please change your filters."
    },
    customEditProps: {
      type: Function,
      default: () => ({})
    },
    hasNextPage: {
      type: Boolean,
      default: false
    },
    actionBtnTitle: {
      type: String,
      default: "Edit"
    },
    text: {
      type: String
    },
    icon: {
      type: String
    },
    actionButton: {
      type: Boolean
    },
    activateOpenWorkspace: {
      type: Boolean,
      default: false
    }
  }
});
